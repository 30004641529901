<template>
  <div>
    <v-dialog v-model="openModalImage" width="500px">
      <v-card>
        <v-card-text class="pt-6">
          <img :src="srcData" width="100%" alt="">
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="textMessages ml-2" :class="IsMeSend ? 'textMessagesMe' : 'textMessagesNotMe'"
         @click="openModalImage = !openModalImage">
      <img :src="srcData" width="100%" alt="">
    </div>
    <v-progress-circular
        v-if="result=='inProgress'"
        :value="uploadPercentage"
        :size="20"
        :width="2"
        color="blue"
    >
      <div style="font-size: .6em;margin-top: 3px;">{{ uploadPercentage }}</div>
    </v-progress-circular>
    <p class="dateMessage" :style="{'text-align': IsMeSend ? 'right' : 'left'}" v-else-if="result == 'success'">
      <v-icon color="success">mdi-check</v-icon>
    </p>
    <template v-else>
      <v-icon color="red">mdi-alert</v-icon>
    </template>
  </div>
</template>

<script>
/*eslint-disable*/
import axios from "axios";
import {EventBus} from "@/event-bus";


export default {
  props: {
    IsMeSend: Boolean,
    message: Object
  },
  data() {
    return {
      openModalImage: false,
      srcData: null,
      imageTypes: [
        'png', 'jpg', 'jpeg', 'gif'
      ],
      status: 'basic',
      downloading: false,
      downloadProgress: 0,
      result: "inProgress",
      uploadPercentage: 0,
    }
  },
  created() {
    this.createImage(this.message.attachment.body).then((resp) => {
      this.srcData = resp
    })
  },
  mounted() {
    this.uploadImage()
  },
  methods: {
    startRecording() {
      if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
        //Feature is not supported in browser
        //return a custom error
        console.log('error')
        return Promise.reject(new Error('mediaDevices API or getUserMedia method is not supported in this browser.'));
      } else {
        console.log('done')
        //Feature is supported in browser
        //...
      }
    },
    createImage(file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.onload = e => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },
    uploadImage() {
      this.$emit('scrollDown')
      let payload = new FormData()
      payload.set('file', this.message.attachment.body)
      payload.set('type', 'file')
      let url = `${process.env.VUE_APP_BASE_URL}user/conversations/${this.message.conversation}/messages`
      EventBus.$emit('scrollDown')
      axios.post(url,
          payload,
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            onUploadProgress: (progressEvent) => {
              // console.log(progressEvent.loaded)
              this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
            }
          }
      ).then(() => {
        this.result = "success"
      }).catch(() => {
        setTimeout(() => {
          EventBus.$emit('deleteFromMessageList', this.message.index);
        }, 1000)
        this.result = 'catch'
      })
    }
  }
}
</script>

<style scoped>
.textMessages {
  padding: 10px;
  text-align: left;
}

.textMessagesNotMe {
  max-width: 300px;
  border-radius: 0px 10px 10px 10px;
  background-color: #78a5ff;
  color: white;
}

.textMessagesMe {
  max-width: 300px;
  border-radius: 10px 0px 10px 10px;
  background-color: #d1dcff;
  color: #574596;
}
</style>