<template>
  <div>

    <div class="textMessages px-5 ml-2 d-flex justify-space-between"
         :class="IsMeSend ? 'textMessagesMe' : 'textMessagesNotMe'">
      <div class="text-center">
        <v-btn outlined fab x-small @click="downloadFile" v-if="result == 'waitForDownload'">
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-progress-circular  indeterminate v-else/>
        <div dir="ltr" class="text-center mt-2 sizeFile">
          {{ message.attachment.size }}
        </div>
      </div>
      <div class="d-flex justify-center align-center">
        {{ message.attachment.name }}.{{ message.attachment.mime }}
      </div>
    </div>
    <p class="dateMessage text-right" :style="{'text-align': IsMeSend ? 'right' : 'left'}">
      {{ message.date }}
    </p>
  </div>
</template>

<script>
import FileDownload from "js-file-download";
import Axios from "axios";

export default {
  props: {
    IsMeSend: Boolean,
    message: Object,
  },
  data() {
    return {
      downloadItem: 0,
      result: "waitForDownload"
    }
  },
  methods: {
    downloadFile() {
      let url = `${process.env.VUE_APP_BASE_URL}user/conversations/${this.message.conversation_id}/getFile/${this.message.contents}`
      this.result = 'downloading'
      Axios({
        method: 'GET',
        url,
        withCredentials: true,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        responseType: 'blob',
        onDownloadProgress: (progress) => {
          // console.log( Math.round(progress.loaded * 100 /
          //     progress.total))
          this.downloadItem = parseInt(Math.round((progress.loaded / progress.total) * 100))
        }
      }).then(resp => {
        FileDownload(resp.data, this.message.contents + "." + this.message.attachment.mime);
      }).finally(() => {
        this.result = 'waitForDownload'
      })
    }
  }
}
</script>

<style scoped>
.textMessages {
  padding: 10px;
  text-align: left;
  overflow-wrap: anywhere;

}

.textMessagesNotMe {
  max-width: 300px;
  border-radius: 0px 10px 10px 10px;
  background-color: #78a5ff;
  color: white;
}

.textMessagesMe {
  max-width: 400px;
  border-radius: 10px 0px 10px 10px;
  background-color: #d1dcff;
  color: #574596;
}

.sizeFile {
  font-size: 10px;
}
.dateMessage{
  font-size: 10px;
}
</style>