<template>
  <div class="fill-height">
    <div class="mt-10 fill-height d-flex flex-column pb-10" v-if="$vuetify.breakpoint.mdAndUp">
      <div class="contactToSupport px-10 white">
        <div class="badgeArea">
          <div class="mt-5 mb-6 d-flex justify-lg-space-between" :class="!$vuetify.rtl && 'flex-row-reverse'">
            <router-link :to="{name:'chat',params:{userId:1189}}">
              <img src="../../assets/image/support.svg" width="40px" alt="">
            </router-link>
            <div class="d-flex justify-center align-center">
              <div class="titleSupport">
                {{ $t('sidebar.chat') }}
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="calenderBox mt-3 pt-4">
        <div class="titleCalenderBox text-center">
          {{ $t('sidebar.calender.title') }}
        </div>
        <Calendar class="mx-5 pb-5" :flow="{
                                             step1:'year',
                                             step2:'month',
                                             step3:'day',
                                           }"
                  v-model="date"
                  :styles="'font-family: IranSans;padding:0 5px;'"
        >
          <template v-slot:prev-icon>
            <v-btn fab x-small class="elevation-0">
              <v-icon fab small class="grey--text">
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:next-icon>
            <v-btn fab x-small class="elevation-0">
              <v-icon fab class="grey--text">
                mdi-chevron-left
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:small-modal>

          </template>
        </Calendar>
      </div>
      <ClockComponent v-if="$vuetify.breakpoint.mdAndUp"/>
    </div>
  </div>
</template>

<script>
import Calendar from "@/components/Calendar/Calendar";
import {mapGetters} from "vuex";
import ClockComponent from "@/components/Main/ClockComponent";

export default {
  components: {ClockComponent, Calendar},
  data() {
    return {
      date: null,
    }
  },

  computed: {
    ...mapGetters({
      profile: 'getProfile'
    }),

  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}

.contactToSupport {
  width: 100%;
  background: white;
  /*padding: 20px;*/
  border-radius: 10px;
  padding: 10px;
  height: 100px;

}

.circle {
  width: 10px;
  border-radius: 50%;
  height: 10px;
}

.statusArea p {
  font-size: 13px;
  line-height: 0pt;
  margin: 5px 5px
}

.descSupport {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6d6d6d;
}

.titleSupport {
  font-size: 12px;
  letter-spacing: normal;
  text-align: left;
  color: #000;

}

.calenderBox {
  border-radius: 10px;
  background: white;
}

.titleCalenderBox {
  font-size: 17px;
  text-align: center;
  color: #000;
}



</style>