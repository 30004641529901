<template>
  <div class="messengerArea">
    <div class="mt-5  fill-height">
      <div class="search">
<!--        <v-text-field-->
<!--            :loader-height="0"-->
<!--            append-icon="mdi-magnify"-->
<!--            label="جست و جو"-->
<!--            outlined-->
<!--            dense-->
<!--            style="border-radius: 10px"-->
<!--        >-->
<!--        </v-text-field>-->
      </div>
      <div class="contactList">
        <ContactList/>
      </div>
    </div>
  </div>
</template>

<script>
import ContactList from "@/components/Messeges/ContactList";

export default {
  components: {ContactList}
}
</script>

<style>
.search .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  background: white;
  border: none !important;
}


.messengerArea {
  height: 80%;
}

.contactList {
  height: 90%;
  /*border-right: 2px solid #dbdbdb;*/
  overflow: auto;
}

</style>