<template>
  <div>
    <v-fade-transition>
      <template v-if="payableFactor">
        <div :key="2" class="payableFactor" v-if="$vuetify.breakpoint.mdAndUp">
          <div class="d-flex " :class=" !$vuetify.rtl && 'flex-row-reverse' ">
            <div class="centering">
              <img src="../../assets/Notify/whiteInfo.svg" alt="">
            </div>
            <div class="flex-grow-1 descArea centering mr-2">
              {{$t('payableFactor.title')}}
              {{ withCurrency(payableFactor.remaining_amount, payableFactor.currency_code, 'long') }}
              {{$t('payableFactor.have')}}
            </div>
            <div>
              <v-btn color="white" outlined class="moreInfoBtn"
                     :to="{name:'factor',params:{factorID:payableFactor.id}}">
                {{$t('payableFactor.details')}}
              </v-btn>
            </div>
          </div>
        </div>
        <div v-else :key="1">
          <v-fade-transition>
            <router-link :to="{name:'factor',params:{factorID:payableFactor.id}}">
              <div class="payableFactorMobile" v-if="payableFactorTimer > 0">
                <div class="d-flex justify-space-between">
                  <div class="mt-1">
                    {{$t('payableFactor.mobTitle')}}
                  </div>
                  <div>
                    <v-progress-circular size="25" width="2" color="white" value="100" v-model="payableFactorTimer"
                                         rotate="270" @click="payableFactorTimer = 0">
                      <template>
                        x
                      </template>
                    </v-progress-circular>
                  </div>
                </div>
              </div>
            </router-link>
          </v-fade-transition>
        </div>
      </template>
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      payableFactor: null,
      payableFactorTimer: 100,
      payableFactorTimerHandler: null,
    }
  },
  methods: {
    getPayableFactor() {
      this.requesting('factor', 'getPayableFactor').then((resp) => {
        if (resp.data.factor) {
          this.payableFactor = resp.data.factor
          if (this.$vuetify.breakpoint.mdAndDown) {
            this.payableFactorTimerHandler = setInterval(() => {
              this.payableFactorTimer--
            }, 250)
          }
        }
      })
    }
  },
  mounted() {
    this.getPayableFactor()
  },
  watch: {
    payableFactorTimer(val) {
      if (val == 0) {
        clearInterval(this.payableFactorTimerHandler)
        // console.log('sto/ped')
      }
    }
  }
}
</script>

<style scoped>
a{
  text-decoration: none;
}
.payableFactorMobile {
  height: 45px;
  width: 100%;
  box-shadow: 0 8px 25px 0 rgba(185, 160, 246, 0.8);
  background-color: rgb(109, 90, 172);
  border-radius: 15px;
  margin-bottom: 10px;
  font-size: 14px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 10px;
}

.payableFactor {
  height: 90px;
  background: #6d5aac;
  width: 100%;
  border-radius: 15px;
  margin-bottom: 15px;
  padding: 25px;
  box-shadow: 0 8px 25px 0 rgba(185, 160, 246, 0.8);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.moreInfoBtn {
  width: 132px !important;
  height: 40px !important;
  border-radius: 10px !important;
}

.descArea {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: right;
  color: rgb(255, 255, 255);
}
</style>