<template>
  <div>
    <v-dialog  v-model="openModalImage" width="500px">
      <v-card>
        <v-card-text class="pt-6" v-if="srcData">
          <img :src="srcData" width="100%" alt="">
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="textMessages ml-2" :class="IsMeSend ? 'textMessagesMe' : 'textMessagesNotMe'"
         @click="openModalImage = !openModalImage">
      <img :src="srcData" width="100%">
    </div>
    <p class="dateMessage " :style="{'text-align': IsMeSend ? 'right' : 'left'}">
      {{ message.date }}
    </p>
  </div>
</template>

<script>
/*eslint-disable*/
import Axios from "axios";
import FileDownload from 'js-file-download';


export default {
  props: {
    IsMeSend: Boolean,
    message: Object
  },
  data() {
    return {
      openModalImage: false,
      srcData: null,
      status: 'basic',
      downloading: false,
      downloadProgress: 0
    }
  },
  created() {
    if (this.isImage(this.message.attachment.extension)) {
      this.downloadFile();
    }
  },
  methods: {
    downloadFile() {
      this.status = 'inProgress';
      let url = this.message.attachment.url;
      let name = this.message.attachment.name + '.' + this.message.attachment.extension;
      let ajaxUrl = '';
      ajaxUrl = process.env.VUE_APP_BASE_URL + `user/conversations/${this.message.conversation_id}/getFile/${url}`;
      this.ajaxDownload(ajaxUrl, name)
    },
    ajaxDownload(url, filename) {
      try {
        return new Promise((resolve, reject) => {
          Axios({
            method: 'GET',
            url,
            withCredentials: true,
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            responseType: 'blob',
            onDownloadProgress: (progress) => {
              this.downloadProgress = parseInt(Math.round((progress.loaded / progress.total) * 100))
            }
          }).then(resp => {
            if (this.isImage(this.message.attachment.extension)) {
              this.assignSource(resp.data);
            } else {
              FileDownload(resp.data, filename);
            }
            resolve();
          }).catch((err) => {
            // store.commit('setLoading', false);
            if (!url.includes('checkLogin')) {
              // EventBus.$emit('notify', 'red', undefined, err.response.data.message);
            }
            reject(err);
          }).finally(() => {
            // store.commit('setLoading', false);
          });
        });
      } catch (err) {
        // EventBus.$emit('notify', 'red', undefined, 'خطایی رخ داده است');
        // store.commit('setLoading', false);
      }
    },
    assignSource(data) {
      let reader = new window.FileReader();
      reader.onload = (e) => {
        this.srcData = reader.result;
        // console.log(this.srcData)
      }
      reader.readAsDataURL(data);
    },
  }
}
</script>

<style scoped>
.textMessages {
  padding: 10px;
  text-align: left;
  min-height: 150px;
  height: auto;
}

.textMessagesNotMe {
  max-width: 300px;
  border-radius: 0px 10px 10px 10px;
  background-color: #78a5ff;
  color: white;
}

.textMessagesMe {
  max-width: 300px;
  border-radius: 10px 0px 10px 10px;
  background-color: #d1dcff;
  color: #574596;
}
.dateMessage{
  font-size: 10px;
}
</style>