<template>
  <div class="">
    <div class="MobNav">
      <div class="d-flex justify-space-between">

        <div class="flex-grow-1 ">
          <img src="../../assets/logo/LogoGreen.png" width="60px" alt="" v-if="$route.name == 'dashboard'">
          <div v-else class="mt-1">
            {{ $t(`routes.${$route.meta.routeKey}`) }}
          </div>
        </div>
        <div class="pt-1">
          <img src="../../assets/menu/menu.png" @click="drawer = true" width="30px" alt="">
          <img src="../../assets/menu/mob/Filter.png" class="mr-2" alt="" @click="openModalEdit"
               v-if="$route.meta.hasOwnProperty('nav') && $route.meta.nav.filter">
        </div>
<!--        <div class="pt-1">-->
<!--          <v-fade-transition mode="out-in">-->
<!--&lt;!&ndash;            <img src="../../assets/menu/icon.png" @click="openSupportChat" width="30px" alt=""&ndash;&gt;-->
<!--&lt;!&ndash;                 v-if="$route.name == 'dashboard'">&ndash;&gt;-->
<!--&lt;!&ndash;            <img src="../../assets/menu/mob/back.png" :class="!$vuetify.rtl && 'en_arrow'" @click="goBack" v-else>&ndash;&gt;-->
<!--          </v-fade-transition>-->
<!--        </div>-->
      </div>
    </div>
    <v-navigation-drawer v-model="drawer" app :width="$vuetify.breakpoint.mdAndUp ? '30%' : '80%'" style=";z-index: 11"
                         :right="$vuetify.rtl">
      <div class="closeDrawer" @click="drawer = false">
        <v-icon small>mdi-close</v-icon>
      </div>
      <div class="d-flex flex-column  fill-height pb-5">
        <div class="mt-10 flex-grow-1">
          <router-link :id="item.route.name" v-for="(item , i) in menuItems" :key="i" :to="item.route"
                       style="text-decoration: none">
            <div class="menuItem "
                 :class="$route.name == item.route.name && 'activeRoute'">
              <div class="d-flex justify-space-between">
                <img :src="item.icon" style="width: 20px">
                <div class="menuItemTitle flex-grow-1 mr-2"
                     :class="$route.name == item.route.name && 'activeRouteTitle'">
                  {{ $t(`menu.${item.key}`) }}
                </div>
              </div>
            </div>
          </router-link>
          <div class="menuItem " @click="logout">
            <div class="d-flex justify-space-between">
              <img src="../../assets/menu/mob/logout.jpg" style="width: 20px">
              <div class="menuItemTitle flex-grow-1 mr-2">
                {{ $t(`logout`) }}
              </div>
            </div>
          </div>

        </div>
        <!--        <div class=" text-center">-->
        <!--          <v-btn text color="#3CBE8B" @click="logout">-->
        <!--            <v-icon small>mdi-close</v-icon>-->
        <!--            {{ $t(`logout`) }}-->
        <!--          </v-btn>-->
        <!--        </div>-->
      </div>
    </v-navigation-drawer>
    <!--    <template v-if="$route.name == 'sessions'">-->
    <!--      <v-fade-transition group mode="out-in">-->
    <!--        <div class="sessionsCalender" :key="1">-->
    <!--          <div class=" calenderBtn d-flex" v-if="!showCalender "-->
    <!--               @click="showCalender = true">-->
    <!--            <template>-->
    <!--              <div>-->
    <!--                <img src="../../assets/image/svg/session/calenderTooltip.svg" alt="">-->
    <!--              </div>-->
    <!--              <div class="text-center mr-2">-->
    <!--                تقویم جلسات-->
    <!--              </div>-->
    <!--              <div class="bottomPointer"></div>-->
    <!--            </template>-->
    <!--          </div>-->
    <!--          <div class="calenderArea" :key="2" v-else>-->
    <!--            <img src="../../assets/image/svg/session/closeCalendar.svg" @click="showCalender = false"-->
    <!--                 style="position: absolute;left: 5px;top: 5px;cursor:pointer;" alt="">-->
    <!--            <img src="../../assets/image/svg/session/today.svg" @click="goToToday"-->
    <!--                 style="position: absolute;right: 5px;top: 5px;cursor:pointer;" alt="">-->
    <!--            <div class="text-center labelCalender">-->
    <!--              تقویم جلسات-->
    <!--            </div>-->
    <!--            <Calendar class="mx-5 pb-5" :flow="{-->
    <!--                                             step1:'year',-->
    <!--                                             step2:'month',-->
    <!--                                             step3:'day',-->
    <!--                                           }"-->
    <!--                      v-model="date"-->
    <!--                      :styles="'font-family: IranSans;padding:0 5px;'"-->
    <!--            >-->
    <!--              <template v-slot:prev-icon>-->
    <!--                <v-btn fab x-small class="elevation-0">-->
    <!--                  <v-icon fab small class="grey&#45;&#45;text">-->
    <!--                    mdi-chevron-right-->
    <!--                  </v-icon>-->
    <!--                </v-btn>-->
    <!--              </template>-->
    <!--              <template v-slot:next-icon>-->
    <!--                <v-btn fab x-small class="elevation-0">-->
    <!--                  <v-icon fab class="grey&#45;&#45;text">-->
    <!--                    mdi-chevron-left-->
    <!--                  </v-icon>-->
    <!--                </v-btn>-->
    <!--              </template>-->
    <!--              <template v-slot:small-modal>-->

    <!--              </template>-->
    <!--            </Calendar>-->

    <!--          </div>-->
    <!--        </div>-->
    <!--      </v-fade-transition>-->
    <!--    </template>-->
    <!--    <v-bottom-navigation fixed style="bottom: 40px;background: transparent"-->
    <!--                         class="elevation-0 pr-5 pl-5 flex-column justify-end">-->
    <!--      <div class="notificationArea">-->
    <!--        <MobileNotification/>-->
    <!--      </div>-->
    <!--      <div class="fixedMenu px-2 mt-1">-->
    <!--        <div class="d-flex justify-space-around">-->
    <!--          <div v-for="(item , i ) in menuItems" :key="i">-->
    <!--            <router-link :to="item.route" class="text-center">-->
    <!--              <img class="navigationItem"-->
    <!--                   style="margin: 0px auto"-->
    <!--                   :class=" $route.name == item.route.name ? 'activeNavigationItem pt-2' : 'pt-3'" :src="item.icon"-->
    <!--                   alt=""-->
    <!--                   width="28">-->
    <!--              <v-fade-transition mode="out-in">-->
    <!--                <div class="titleNavigationItem" v-if=" $route.name == item.route.name">-->
    <!--                  {{ $t(`mobileMenu.${item.key}`) }}-->
    <!--                </div>-->
    <!--              </v-fade-transition>-->
    <!--            </router-link>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </v-bottom-navigation>-->
  </div>
</template>

<script>
/*eslint-disable*/
import {mapGetters} from "vuex";
import Calendar from "@/components/Calendar/Calendar";
import moment from "moment-jalaali";
import MobileNotification from "@/components/Nitification/MobileNotification";

export default {
  components: {MobileNotification, Calendar},
  data() {
    return {
      drawer: false,
      showCalender: false,
      date: null,
      menuItems: [
        {
          icon: require('../../assets/menu/mob/day.png'),
          title: 'داشبورد',
          key: "dashboard",
          route: {
            name: 'dashboard',
          }
        },
        {
          icon: require('../../assets/menu/mob/sofa.png'),
          title: 'درخواست جلسه',
          key: 'session_request',
          route: {name: 'sessions-request',}
        },
        {
          icon: require('../../assets/menu/mob/packages.png'),
          title: 'بسته‌ها',
          key: "packages",
          route: {
            name: 'packages',
          }
        },
        {
          icon: require('../../assets/menu/mob/sofa.png'),
          title: 'جلسات',
          key: "sessions",
          route: {
            name: 'sessions',
          }
        },
        {
          icon: require('../../assets/menu/mob/wallet.png'),
          title: 'امور مالی',
          key: "factor",
          route: {
            name: 'wallet',
          }
        },
        // {
        //   icon: require('../../assets/menu/mob/messenger.png'),
        //   title: 'پیام‌ها',
        //   key: "messenger",
        //   route: {
        //     name: 'messenger',
        //   }
        // },


        // {
        //   icon: require('../../assets/menu/mob/tasks.png'),
        //   title: 'تمرین‌ها',
        //   key: "tasks",
        //   route: {
        //     name: 'tasks',
        //   }
        // },
        {
          icon: require('../../assets/menu/mob/profile.png'),
          title: 'پروفایل',
          key: "profile",
          route: {
            name: 'setting',
          }
        },
      ],


      navigationMenu: [
        {
          title: 'تنظیمات',
          key: "setting",
          route: {
            name: 'setting',
          }
        },
        {
          title: 'تراکنش‌ها/کیف پول',
          key: "wallet",
          route: {
            name: 'wallet'
          }
        },
        {
          title: 'پشتیبان اختصاصی',
          key: "account_manager",
          route: {name: 'chat', params: {userId: 1189}}
        },
        {
          title: 'پیام‌رسان',
          key: 'chat',
          route: {
            name: 'messenger'
          }
        },

      ]
    }
  },
  computed: {
    ...mapGetters({
      'profile': 'getProfile',
      cart: 'getCart'
    }),
    cartLength() {
      if (this.cart) {
        return Object.keys(this.cart.items).length
      } else {
        return 0;
      }
    }
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    goToToday() {
      this.date = new Date()
    },
    openSupportChat() {
      this.$emit('openSupportChat')
    },
    openModalEdit() {
      this.$emitEvent('FilterModalTrigger', true)
    },
    addTestsToManu() {
      if (this.profile) {
        // if (!this.profile.psytest_register) {
          let index = this.menuItems.findIndex(item => item.key == 'tests')
          if (index == -1) {
            this.menuItems.push({
              icon: require('../../assets/menu/mob/sofa.png'),
              title: 'تست‌ها',
              key: "tests",
              route: {
                name: 'tests',
              }
            },)
          }
        // }
      }
    }
  },
  mounted() {
    this.$onEvent('changeStatusCalenderMobile', (val) => {
      this.showCalender = val
    })
    this.addTestsToManu()
  },
  watch: {
    '$route'() {
      this.showCalender = false
    },
    'profile'() {
      this.addTestsToManu()
    }
  }
}
</script>

<style scoped>
.closeDrawer {
  position: absolute;
  left: 5px;
  top: 5px;
}

.menuItem {
  margin: 0px 10px 15px 10px;
  width: 100%;
  padding-top: 10px;
  text-align: center;
  transition: all 0.3s;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
}


.menuItemTitle {
  color: #959595;
  text-decoration: none;
  font-size: 12px;
}

.menuItem img {
  margin: 0px auto;
  width: 30px;
}

.activeRouteTitle {
  color: #3CBE8B;
}

.activeRoute {
  /*box-shadow: 0 8px 13px 0 rgba(60, 190, 139, 0.38);*/
  background: #EAF8F2;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.3s;
  padding-right: 5px;
  color: white !important;
}

.MobNav {
  background: white;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
  height: 55px;
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0px;
  z-index: 10;
  left: 0px;
  right: 0px;
}

.labelCalender {
  font-size: 14px;
  margin-bottom: -10px;
}

.calenderArea {
  background: white;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  padding-top: 15px;
  position: relative;
}

.calenderBtn {
  width: 150px;
  min-height: 36px;
  margin: 10px auto;
  padding: 6px 13px 1px;
  border-radius: 15px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.07);
  font-size: 14px;
  text-align: center;
  background-color: rgb(255, 255, 255);
  transition: all 0.3s;

}

.sessionsCalender {
  right: 5%;
  left: 5%;
  bottom: 100px;
  z-index: 10;
  position: fixed;
}

.bottomPointer {
  width: 50px;
  height: 2px;
  background: #4576d8;
  position: absolute;
  bottom: 10px;
  right: calc(50% - 25px);
}

a {
  text-decoration: none;
  color: inherit;
}

.titleNavigationItem {
  font-size: 10px;
  margin-top: -8px;
  color: white;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

.avatarArea {
  width: 102px;
  height: 102px;
  margin: 0px auto;
  box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
  border-radius: 50%;
}

.username {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #6d6d6d;
}

.menuitem {
  width: 60%;
  padding: 10px;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin: 10px auto;
  transition: all 0.3s;
}

.menuitemActive {
  background: #edfafa;
  transition: all 0.3s;
  border-radius: 15px;
}

.ShareArea {
  font-size: 12px;
}

.closeBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.fixedMenu {
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 8px 13px 0 rgba(60, 190, 139, 0.38);
  background-color: #47c8bd;
  border: 1px solid #ffffff;
}

.navigationItem {
  opacity: 0.7;
  transition: all 0.3s;
}

.activeNavigationItem {
  opacity: 1;
  transition: all 0.3s;
}

.havePackage {
  position: absolute;
  top: 10px;
  left: 10px;
}
</style>