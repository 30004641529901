<template>
  <div class="fill-height">
    <div class="clockBox fill-height mt-3 flex-grow-1 d-flex flex-column justify-center"
         v-if="$vuetify.breakpoint.mdAndUp">
      <div class="clockArea mt-4 activeOverlay  cartPanel d-flex py-3 flex-column justify-space-around ">
        <div class="titlePanel text-center ">
          {{ $t('sidebar.localClock') }}
        </div>
        <div class="  px-6">
          <div class="clock">
            <div class="clockHour" :style="{transform: `rotate(${hourTransform[hour]}deg)`}"></div>
            <div class="clockMinute" :style="{transform: `rotate(${minuteDeg}deg)`}"></div>
            <div class="clockPointer"></div>
            <!--                              <div class="clockMinute"></div>-->
          </div>
        </div>
        <router-link :to="{name:'userOnboarding',query:{step:4}}">
          <div>
            <div class="timezone d-flex justify-center mt-4">
              <div>
                {{ userTZ }}
              </div>
              <div>
                <img src="../../assets/icons/timeZone.svg" width="20" class="mr-1" alt="">
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div v-else class="">
      <div class="clockAreaMob">
        <div class="d-flex fill-height flex-column  justify-center  ">
          <div class="d-flex justify-space-between">
            <div>
              <div class="clockBoxMob d-flex flex-column ">
                <div class="clock">
                  <div class="clockHour" :style="{transform: `rotate(${hourTransform[hour]}deg)`}"></div>
                  <div class="clockMinute" :style="{transform: `rotate(${minuteDeg}deg)`}"></div>
                  <div class="clockPointer"></div>
                  <!--                              <div class="clockMinute"></div>-->
                </div>
              </div>
            </div>
            <div class="d-flex justify-center flex-column ">
              <div>
                <div class="timezoneTitle text-left">
                  وقت محلی
                </div>
                <div>
                  <router-link :to="{name:'userOnboarding',query:{step:4}}">
                    <div>
                      <div class="timezone d-flex justify-center mt-4">
                        <div>
                          {{ userTZ }}
                        </div>
                        <div>
                          <img src="../../assets/icons/timeZone.svg" width="20" class="mr-1" alt="">
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'ClockComponent',
  data() {
    return {
      hourTransform: {
        1: '210',
        2: '230',
        3: '270',
        4: '310',
        5: '330',
        6: '360',
        7: '20',
        8: '40',
        9: "90",
        10: '130',
        11: '150',
        12: '180'
      },
      currentTime: null,
      clockZindex: 0,
    }
  },
  mounted() {
    this.currentTime = Date().toLocaleString("en-US", {timeZone: this.userTZ})
    setInterval(() => {
      this.currentTime = new Date().toLocaleString("en-US", {timeZone: this.userTZ})
    }, 60000)
  },
  computed: {
    ...mapGetters({
      profile: 'getProfile'
    }),
    userTZ() {
      if (this.profile.tz) {
        return this.profile.tz
      }
      return "UTC"
    },
    hour() {
      let hour = new Date(this.currentTime).getHours()
      if (hour > 12) {
        return hour - 12
      }
      return hour
    },
    minute() {
      return new Date(this.currentTime).getMinutes()
    },
    minuteDeg() {
      let deg = this.minute * 6
      return deg + 180
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .clock {
    width: 60px !important;
    height: 60px !important;
    border: 1px solid #ebf0fa;
  }

  .clockHour {
    height: 15px !important;
    width: 1px !important;
    left: 50% !important;
    top: 50% !important;
  }

  .clockMinute {
    height: 20px !important;
    width: 1px !important;
    left: 50% !important;
  }

  .clockPointer {
    width: 5px !important;
    height: 5px !important;
    top: 45% !important;
    left: 48% !important;
  }

  .timezoneTitle {
    font-size: 14px !important;
  }

  .timezone {
    font-size: 12px !important;
  }

  .timezone img {
    width: 15px !important;
  }
}

a {
  text-decoration: none;
}

.clockArea {
  position: relative;
  height: 100%;
}

.clock {
  width: 135px;
  height: 135px;
  border: 1px solid #ebf0fa;
  margin: auto;
  border-radius: 50%;
  position: relative;
}

.clockHour {
  position: absolute;
  content: '';
  height: 35px;
  width: 3px;
  background: #1c53c1;
  top: 48%;
  left: calc(50% - 5px);
  border-radius: 5px;
  transform-origin: top;
}

.clockPointer {
  width: 8px;
  height: 8px;
  background: #1c53c1;
  position: absolute;
  border-radius: 10px;
  top: 45%;
  left: calc(49% - 5px);
}

.clockBox {
  background: white;
  border-radius: 15px;
}

.clockMinute {
  position: absolute;
  content: '';
  height: 50px;
  width: 3px;
  background: #1c53c1;
  top: 48%;
  left: calc(50% - 5px);
  border-radius: 5px;
  transform-origin: top;
  transform: rotate(190deg);
}

.timezone {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #4576d8;
}

.timezone img {
  margin-top: 2px;
}

.clockAreaMob {
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
  height: 130px;
  padding: 20px;
}

.clockBoxMob {
  width: 100px;
  height: 100px;
}
</style>