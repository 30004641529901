<template>
  <div>
    <template v-if="loadNotification">
      <PayableFactor/>
    </template>
  </div>
</template>

<script>
import PayableFactor from "@/components/Main/PayableFactor";

export default {
  components: {PayableFactor},
  data() {
    return {
      routeMostLoadNotification: ['dashboard']
    }
  },
  computed: {
    loadNotification() {
      return this.routeMostLoadNotification.find(item => item == this.$route.name) != undefined
    }
  },
}
</script>

<style scoped>

</style>