<template>
  <div>
    <div class="contactItem flex-row-reverse  d-flex justify-space-between" v-for="(contact , i ) in contacts" :key="i"
         @click="selectConversion(contact)"
         :class="isActiveConversion(contact) && 'activeContact'">
      <div class="text-left flex-grow-1 d-flex flex-column justify-center ml-2">
        <div class="d-flex ">
          <div>
            <template v-if="contact.hasOwnProperty('hasMesseages') && contact.hasMesseages">
              <v-fade-transition>
                <img src="../../assets/image/png/messenger/pointerNewMessages.png" alt="">
              </v-fade-transition>
            </template>
          </div>
          <div class="pr-3 ">
            {{ contact.full_name }}
          </div>
        </div>
      </div>
      <div>
        <img class="avatarImage"
             :src="contact.avatar ?  contact.avatar : require('@/assets/image/png/messenger/avatar.png')" width="50px"
             alt="">
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ContactList",
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      'contacts': 'getContacts',
    }),
    // renderContacts(){
    //
    // }
  },
  methods: {
    isActiveConversion(contact) {
      if (contact.id == this.$route.params.userId) {
        return true
      }
      return false
    },
    getContacts() {
      this.$emitEvent('setLoading', true)
      this.requesting('messenger', 'getContacts').then(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    selectConversion(contact) {
      this.$emitEvent('setLoading', true)
      this.$emit('selectingUser', 'messenger')
      this.$router.push({name: 'chat', params: {userId: contact.id}})
      if (this.$vuetify.breakpoint.mdAndDown) {
        if (this.$route.params.userId) {
          this.$store.commit('SET_MESSAGES', {data: []})
        }
      }
    }
  },
  mounted() {

    this.getContacts()
  }
}
</script>

<style scoped>


.contactItem {
  margin-bottom: 10px;
  padding: 10px 10px 5px 10px;
  background-color: white;
  border-right: 2px solid transparent;

  transition: all 0.3s ease-in-out;
  cursor: pointer;
  box-shadow: 0 3px 8px -6px rgba(0,0,0,.2);
}

.activeContact {
  background: rgba(0,230,180,.1);
  border-right: 2px solid #47c8bd;
  transition: all 0.3s;
  box-shadow: 0 6px 12px -10px rgba(0,0,0,.3);

}

.activeContact img {
  border: 2px solid #46b1a1;
}

.avatarImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

</style>