<template>
  <div class="pa-6 fill-height">
    <div class="boxMessenger fill-height">
      <div class="d-flex flex-column fill-height">
        <div class="titleBox d-flex pb-5">
          <div class="flex-grow-1 text-center">
            {{ $t('dashboard.chat.title') }}
          </div>
          <v-btn fab x-small elevation="0" color="transparent" @click="closeSupportChatSidebar" style="cursor: pointer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="messengerArea py-2 flex-grow-1 " id="messagesArea">
          <v-progress-linear indeterminate v-if="localLoader"></v-progress-linear>
          <div v-for="(message , i) in messages" :key="i">
            <template v-if="message.type == 'file' ">
              <template v-if=" isImage(message.attachment.mime)">
                <div class="message d-flex" :class="profile.id != message.user_id && 'justify-end'">
                  <div>
                    <ImageMessages :message="message" :is-me-send="profile.id === message.user_id"/>
                  </div>
                </div>
              </template>
              <template v-else>
                <template>
                  <div class="message d-flex" :class="profile.id != message.user_id && 'justify-end'">
                    <FileMessage :message="message" :is-me-send="profile.id === message.user_id"/>
                  </div>
                </template>
                <!--          <template v-else>-->
                <!--            <div class="message d-flex" :class="profile.id != message.user_id && 'justify-end'">-->
                <!--              <VoiceMassege :message="message" :is-me-send="profile.id === message.user_id"/>-->
                <!--            </div>-->
                <!--          </template>-->

              </template>
            </template>
            <template v-else-if="message.type == 'text'">
              <div class="message d-flex" :class="profile.id != message.user_id && 'justify-end'">
                <div>
                  <TextMessages :message="message" :is-me-send="profile.id === message.user_id"/>
                </div>
              </div>
            </template>
            <template v-else-if="message.type == 'imageUpload'">
              <UploadedImage :message="message" :is-me-send="profile.id === message.user_id"
                             @scrollDown="scrollToDown"/>
            </template>
            <template v-else-if="message.type == 'FileUpload'">
              <UploadedFile :message="message" :is-me-send="profile.id === message.user_id" @scrollDown="scrollToDown"/>
            </template>
            <template v-else-if="message.type == 'voiceUpload'" class="mb-2">
              <UploadVoice :keyVoice="i" :is-me-send="profile.id === message.user_id" :message="message"
                           @scrollDown="scrollToDown"/>
            </template>
          </div>
        </div>
        <div class="actionArea">
          <div class="d-flex">
            <div class="flex-grow-1">
              <div class="inputArea ">
                <input type="text" @keypress.enter="sendMessages" v-model="textMessage"
                       :placeholder="$t('dashboard.chat.placeholder')"
                       style="height: 25px !important;">
              </div>
            </div>
            <div class="mt-1 mr-2">
              <v-icon style="transform: rotate(180deg)" @click="sendMessages">mdi-send</v-icon>
            </div>
            <div class="mt-1 mr-2">
              <input style="display: none;" type="file" id="image" accept="image/*" ref="imageInput"
                     v-on:change="sendImageMessage"/>
              <label for="image" style="cursor: pointer">
                <v-icon style="">mdi-plus</v-icon>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ImageMessages from "@/components/Messeges/ImageMessages";
import FileMessage from "@/components/Messeges/FileMessage";
import TextMessages from "@/components/Messeges/TextMessages";
import UploadedFile from "@/components/Messeges/UploadedFile";
import UploadedImage from "@/components/Messeges/UploadedImage";
import UploadVoice from "@/components/Messeges/UploadVoice";
import moment from "moment-jalaali";

export default {
  components: {UploadVoice, UploadedImage, UploadedFile, TextMessages, FileMessage, ImageMessages},
  computed: {
    ...mapGetters({
      'messages': 'getMessages',
      'profile': 'getProfile',
    })
  },
  data() {
    return {
      textMessage: null,
      binded: false,
      localLoader: false,
      conversation: null,
      paginationLock: false,
      pagination: {
        page: 1,
        total: 0,
      }
    }
  },
  methods: {
    closeSupportChatSidebar() {
      this.$store.dispatch('clearMessages')
      this.$emit('closeSupportChatSidebar')
    },
    sendMessages() {
      if (this.textMessage == null) {
        this.$emitEvent('notify', {title: this.$t('Notify.requiredChat'), color: 'red'})
        return;
      }
      this.requesting('messenger', 'sendMessage', {
        conversionId: this.conversation
      }, {
        contents: this.textMessage,
        type: 'text',
      })
      this.$store.dispatch('pushMessage', {
        type: 'text',
        user_id: this.profile.id,
        contents: this.textMessage,
      })
      this.textMessage = null;
      this.scrollDown()
    },

    getMessages() {
      this.paginationLock = true
      this.requesting('messenger', 'getMessageByUser',
          {
            'userId': '1189',
            page: this.pagination.page
          }).then((resp) => {
        // this.conversation =
        this.conversation = resp.data.conversation.id
        this.scrollDown()
        this.pagination = {
          page: resp.data.pagination.current_page,
          total: resp.data.pagination.total_pages,
        }
        if (!this.binded)
          this.bindListener()
      }).finally(() => {
        this.paginationLock = false
      })
    },
    scrollDown() {
      var messagesArea = document.getElementById('messagesArea');
      messagesArea.scrollTop = messagesArea.scrollHeight;
    },
    bindListener() {
      // console.log(document.getElementById('routerArea', '12121212'))
      this.binded = true
      document.getElementById('messagesArea').addEventListener(this.$vuetify.breakpoint.mdAndUp ? 'scroll' : 'touchmove', (event) => {
        if (event.target.scrollTop == 0 && this.pagination.page != this.pagination.total && !this.paginationLock) {
          this.pagination.page++;
          this.getMessages()
        }
      })
    },
    sendImageMessage() {
      let file = this.$refs.imageInput.files[0]
      this.$store.dispatch('pushMessage', {
        type: "imageUpload",
        conversation: this.conversation,
        user_id: this.profile.id,
        index: this.messages.length,
        attachment: {
          created_at: new moment(new Date()).format('jYYYY/jMM/jDD HH:mm'),
          body: file,
          mime: file.type,
        }
      })
      // this.sendMessages('image', payload)
    },

  },
  mounted() {
    this.getMessages()
  },

}
</script>

<style scoped>
.messengerArea {
  overflow-x: auto;
}

.boxMessenger {
  padding: 20px;
  border-radius: 15px;
  background: white;
  border: 1px solid #3CBE8B;

}

.inputArea {
  background: #E1E1E1;
  border-radius: 12px;
  padding: 5px;
  height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titleBox {
  border-bottom: 1px solid #E1E1E1;
}
</style>