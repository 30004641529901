<template>
  <div>
    <div class="textMessages ml-2" :class="IsMeSend ? 'textMessagesMe' : 'textMessagesNotMe'">
      {{message.contents}}
    </div>
    <p class="dateMessage" :style="{'text-align': IsMeSend ? 'right' : 'left'}">
      {{ message.date }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    IsMeSend: Boolean,
    message:Object,
  },
}
</script>

<style scoped>
.textMessages {
  padding: 10px;
  text-align: left;
  overflow-wrap: anywhere;

}

.textMessagesNotMe {
  max-width: 300px;
  border-radius: 0px 10px 10px 10px;
  background-color: #78a5ff;
  color: white;
}

.textMessagesMe {
  max-width: 400px;
  border-radius: 10px 0px 10px 10px;
  background-color: #d1dcff;
  color: #574596;
}

.dateMessage{
  font-size: 10px;
}
</style>