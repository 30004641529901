<template>
  <div class="voice d-flex align-center" :class="IsMeSend ? 'voiceMe' : 'voiceMeNotMe'">
    <div class="seekArea" :style="{right:(rightPX + 'px') }"></div>
    <div class="flex-grow-1">
      <div :id="`waveform${keyVoice}`"></div>
    </div>
    <div>
      <v-icon @click="play" v-if="statusVoice == 'pause'">mdi-play</v-icon>
      <v-icon @click="pause" v-else>mdi-pause</v-icon>
    </div>
    <v-progress-circular
        v-if="result=='inProgress'"
        :value="uploadPercentage"
        :size="20"
        :width="2"
        color="blue"
    >
      <div style="font-size: .6em;margin-top: 3px">{{ uploadPercentage }}</div>
    </v-progress-circular>
    <v-icon v-else-if="result == 'success'" color="green">mdi-check</v-icon>
    <v-icon v-else color="red">mdi-alert</v-icon>
  </div>
</template>

<script>
import WaveSurfer from "wavesurfer.js";
import {EventBus} from "@/event-bus";
import axios from "axios";
/*eslint-disable*/
export default {
  props: {
    keyVoice: Number,
    IsMeSend: Boolean,
    message: Object,
  },
  data() {
    return {
      wavesurfer: null,
      statusVoice: 'pause',
      rightPX: 200,
      sec: 0,
      uploadPercentage: 0,
      result: "inProgress"
    }
  },
  mounted() {
    this.wavesurfer = WaveSurfer.create({
      container: document.querySelector(`#waveform${this.keyVoice}`),
      backend: 'MediaElement',
      height: 30,
      barWidth: 2,
      waveColor: 'white',
      progressColor: 'blue  ',
      audioRate: 1
    });
    this.uploadVoice()
    this.wavesurfer.load(this.message.attachment.body);
    this.wavesurfer.on('ready', () => {
      this.wavesurfer.on('play', () => {
        this.t1 = Date.now();
        this.statusVoice = 'play'
      })
      // this.wavesurfer.on('audioprocess', () => {
      //   this.fallowPinter()
      // })
      this.wavesurfer.on('finish', () => {
        this.rightPX = 200
      })
      // this.wavesurfer.on('seek', () => {
      //   console.log('seeking .....')
      //   let perPX = 200 / parseInt(this.wavesurfer.getDuration())
      //   this.rightPX -= perPX
      //   console.log(perPX)
      // })
      this.wavesurfer.on('pause', () => {
        this.statusVoice = 'pause'
      })
    });

    EventBus.$on('pauseAllPlayingVoice', () => {
      this.wavesurfer.pause()
    })
  },
  methods: {
    play() {
      EventBus.$emit('pauseAllPlayingVoice')
      this.wavesurfer.play()
    },
    pause() {
      this.wavesurfer.pause()
    },
    uploadVoice() {
      this.$emit('scrollDown')

      let payload = new FormData()
      let url = `${process.env.VUE_APP_BASE_URL}user/conversations/${this.message.conversation}/messages`
      payload.set('file', this.message.attachment.audioBlob)
      payload.set('type', 'file')
      axios.post(url,
          payload,
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            onUploadProgress: (progressEvent) => {
              // console.log(progressEvent.loaded)
              this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
            }
          }
      ).then(() => {
        this.result = 'success'
      }).catch(() => {
        this.result = 'catch'
      })
    }
  }
}
</script>

<style scoped>
.seekArea {
  position: absolute;
  top: 0px;
  /*right: px;*/
  left: 0px;
  bottom: 0px;
  background: blue;
}

.voice {
  overflow: hidden;
  /*z-index: 9999;*/
  position: relative;
  height: 40px;
  width: 200px;
  margin: 10px 0px;
}

.voiceMeNotMe {
  background: #78a5ff;
  border-radius: 0px 10px 10px 10px;
  margin-left: 10px;
  padding-left: 10px;
}

.voiceMe {
  background: #d1dcff;
  border-radius: 10px 0px 10px 10px;
  margin-left: 10px;
  padding: 0px 10px;

}
</style>