<template>
  <div class="fill-height">
    <v-row class="mx-10 fill-height pa-0">
      <v-col cols="12" lg="3" class="fill-height  pa-0">
        <MainNavbar/>
      </v-col>
      <v-col cols="12" lg="9">
       <v-fade-transition mode="out-in">
<!--         <template v-if="$route.name != 'messenger' && $route.name != 'chat'">-->
<!--           <OverviewSidebar/>-->
<!--         </template>-->
<!--         <template v-else>-->
<!--           <ChatSidebar/>-->
<!--         </template>-->
       </v-fade-transition>
      </v-col>

    </v-row>
  </div>
</template>

<script>
/*eslint-disable*/
import MainNavbar from "@/components/Navbar/MainNavbar";
import OverviewSidebar from "@/components/Sidebar/OverviewSidebar";
import ChatSidebar from "@/components/Sidebar/ChatSidebar";

export default {
  components: {ChatSidebar, OverviewSidebar, MainNavbar}
}
</script>

<style scoped>

</style>