const _daysInMonths = [31, 31, 31, 31, 31, 31, 30, 30, 30, 30, 30, 29];

export default {
    methods: {
        getSessions(year, month, isGregorian = false) {

            let from = year + "-" + month + "-1"
            let end = year + "-" + month + "-" + _daysInMonths[(month - 1)]


            let payload = {
                orderBy: 'session_date:asc',
                from_date: isGregorian ? from : this.jalaliToGregorian(from),
                to_end_of_day: isGregorian ? end : this.jalaliToGregorian(end),
            }

            this.requesting('session', 'getSessions', '', payload)

        },
    }
}