<template>
  <div>
    <div class="textMessages px-5 ml-2 d-flex justify-space-between"
         :class="IsMeSend ? 'textMessagesMe' : 'textMessagesNotMe'">
      <div class="text-center">
        <v-progress-circular
            v-if="result=='inProgress'"
            :value="uploadPercentage"
            :size="20"
            :width="2"
            color="blue"
        >
          <div style="font-size: .6em;margin-top: 3px;">{{ uploadPercentage }}</div>
        </v-progress-circular>
        <v-icon v-else-if="result == 'success'" color="green">mdi-check</v-icon>
        <v-icon v-else color="red">mdi-alert</v-icon>
      </div>
      <div class="d-flex justify-center align-center">
        {{ message.attachment.name }}
      </div>
    </div>
    <p class="dateMessage" :style="{'text-align': IsMeSend ? 'right' : 'left'}">
      {{ message.date }}
    </p>
  </div>
</template>

<script>
// import FileDownload from "js-file-download";
// import Axios from "axios";
import {EventBus} from "@/event-bus";
import axios from "axios";

export default {
  props: {
    IsMeSend: Boolean,
    message: Object,
  },
  data() {
    return {
      uploadPercentage: 0,
      result: "inProgress"
    }
  },
  mounted() {
    console.log(this.message)
    this.uploadFile()
  },
  methods: {
    uploadFile() {
      this.$emit('scrollDown')
      let payload = new FormData()
      payload.set('file', this.message.attachment.body)
      payload.set('type', 'file')
      let url = `${process.env.VUE_APP_BASE_URL}user/conversations/${this.message.conversation}/messages`
      EventBus.$emit('scrollDown')
      axios.post(url,
          payload,
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            onUploadProgress: (progressEvent) => {
              // console.log(progressEvent.loaded)
              this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
            }
          }
      ).then(() => {
        this.result = "success"
      }).catch(() => {
        setTimeout(() => {
          EventBus.$emit('deleteFromMessageList', this.message.index);
        }, 1000)
        this.result = 'catch'
      })
    }

  }
}
</script>

<style scoped>
.textMessages {
  padding: 10px;
  text-align: left;
  overflow-wrap: anywhere;

}

.textMessagesNotMe {
  max-width: 300px;
  border-radius: 0px 10px 10px 10px;
  background-color: #78a5ff;
  color: white;
}

.textMessagesMe {
  max-width: 400px;
  border-radius: 10px 0px 10px 10px;
  background-color: #d1dcff;
  color: #574596;
}

.sizeFile {
  font-size: 10px;
}
</style>